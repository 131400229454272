import React, { useRef } from "react";
import { Carousel } from "flowbite-react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import HomepageComments from "../Components/HomepageComments";
import HomePageHotDeals from "../Components/HomePageHotDeals";
import HomepagePopular from "../Components/HomepagePopular";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
// responsive enable
function Homepage() {


    const curRef = useRef();
    
    
    // Inside your component
const navigate = useNavigate();

    const eventScroll = (direction) => {
      if (direction === "left") {
        if (curRef) {
          curRef.current.scrollLeft -= 300;
        }
      } else {
        if (curRef) {
          curRef.current.scrollLeft += 300;
        }
      }
    };



    const handleShopNow = () => {
      navigate('/drones');
    };
  
    return (
      <div>
        <Navbar/>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="h-56 sm:h-64 xl:h-80 2xl:h-96 ">
            <Carousel slideInterval={3000}>
              <img
                src="images/HomeImage.png"
                alt="..."
              />
              <img
                src="images/EndImage.png"
                alt="..."
              />
              <img
                src="images/HomeImage.png"
                alt="..."
              />
              <img
                src="images/EndImage.png"
                alt="..."
              />
              <img
                src="images/HomeImage.png"
                alt="..."
              />
            </Carousel>
          </div>
          <div className="p-5 mt-3 sm:mx-auto sm:w-full sm:max-w-xl 2xl:max-w-7xl">
           
            <div className="text-center p-5 mt-8">
              <h3 className="text-xl font-bold tracking-wide">Our Categories</h3>
              <p className="font-semibold text-gray-400 tracking-wide mt-1">
                Explore our main categories on the go
              </p>
            </div>
  
            <div className="2xl:flex flex-row align-middle items-center p-3 justify-between">
              {/* one category */}
              <div className="flex flex-col text-center font-semibold text-sm m-2">
                 <div className="flex flex-col p-7 pl-10 pr-10 bg-gray-100 rounded-md h-64 w-64"> 
              <a href={"/gimbles"}>
                  <img
                    alt="Your Company"
                    src="images/Gimbles.png"
                    className="mx-auto  hover:scale-x-110 hover:scale-y-110 hover:transition duration-500 ease-in-out"
                    width={200}
                    // height={30}
                  />
                 </a>

                </div>
                <p className="mt-2">Gimbal</p>
              </div>
              {/* one category */}
              <div className="flex flex-col text-center font-semibold text-sm m-2">
                 <div className="flex flex-col p-7 pl-10 pr-10 bg-gray-100 rounded-md h-64 w-64"> 
                 
                <a href={"/drones"}>
                  <img
                    alt="Your Company"
                    src="images/Drones.png"
                    className="mx-auto hover:scale-x-110 hover:scale-y-110 hover:transition duration-500 ease-in-out mt-10"
                    width={200}
                    // height={30}
                  />
                  </a>

                </div>
                <p className="mt-2">Drone & Accessories</p>
              </div>
              {/* one category */}
              <div className="flex flex-col text-center font-semibold text-sm m-2">
                 <div className="flex flex-col p-7 pl-10 pr-10 bg-gray-100 rounded-md h-64 w-64"> 
              <a href={"/smartwatches"}>
                  <img
                    alt="Your Company"
                    src="images/Smartwatches.png"
                    className="mx-auto hover:scale-x-110 hover:scale-y-110 hover:transition duration-500 ease-in-out mt-4"
                    width={200}
                    // height={30}
                  />
                  </a>
                </div>
                <p className="mt-2">Smart Watch</p>
              </div>
              {/* one category */}
              <div className="flex flex-col text-center font-semibold text-sm m-2">
                 <div className="flex flex-col p-7 pl-10 pr-10 bg-gray-100 rounded-md h-64 w-64"> 
              <a href={"/others"}>
                  <img
                    alt="Your Company"
                    src="images/others1.png"
                    className="mx-auto hover:scale-x-110 hover:scale-y-110 hover:transition duration-500 ease-in-out mt-4"
                    width={200}
                    // height={30}
                  />
                  </a>
                </div>
                <p className="mt-2">Other Accessories</p>
              </div>
            </div>
  
            {/* fre ship sectoin */}
            <div className="2xl:flex align-middle items-center p-3 justify-between bg-gray-100 rounded-md mt-12 ">
              {/* free shipping */}
              <div className="flex flex-row text-center font-semibold text-sm m-2">
                <div className="flex flex-col -red-400 p-4 bg-gray-100 rounded-md">
                  <div className="relative">
                    <img
                     // alt=" free shipping"
                      src="/images/Vector.png"
                      className="mx-auto relative "
                      width={80}
                      // height={30}
                    />
                    <img
                      //alt="Your Companyx"
                      src="/images/freeShipping.png"
                      className="mx-auto absolute bottom-0  mb-3 ml-4"
                      width={50}
                      // height={30}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start ml-1 text-left">
                  <p className="mt-2">Free Shipping</p>
                  <p className="mt-2 text-gray-400 ">
                    Free shipping for All fully Payment Item
                  </p>
                </div>
              </div>
              {/* onr to one warranty */}
              <div className="flex flex-row text-center font-semibold text-sm m-2">
                <div className="flex flex-col -red-400 p-4 bg-gray-100 rounded-md">
                  <div className="relative">
                    <img
                      alt="Your Company"
                      src="/images/Vector.png"
                      className="mx-auto relative "
                      width={80}
                      // height={30}
                    />
                    <img
                      alt="Your Companyx"
                      src="/images/OnetoOne.png"
                      className="mx-auto absolute bottom-0 ml-4 mb-1"
                      width={50}
                      // height={30}
                    />
                  </div>
                </div>
                <div className="flex flex-col text-left items-start ml-1">
                  <p className="mt-2">One to one Warranty</p>
                  <p className="mt-2 text-gray-400">
                    Within 30 days for an exchange
                  </p>
                </div>
              </div>
              {/* easy payment */}
              <div className="flex flex-row text-center font-semibold text-sm m-2">
                <div className="flex flex-col -red-400 p-4 bg-gray-100 rounded-md">
                  <div className="relative">
                    <img
                      alt="Your Company"
                      src="/images/Vector.png"
                      className="mx-auto relative "
                      width={80}
                      // height={30}
                    />
                    <img
                      alt="Your Companyx"
                      src="/images/EasyPayment.png"
                      className="mx-auto absolute bottom-0 ml-1 mb-3 right-3"
                      width={50}
                      // height={30}
                    />
                  </div>
                </div>
                <div className="flex flex-col text-left items-start ml-1">
                  <p className="mt-2">Easy Payment</p>
                  <p className="mt-2 text-gray-400">
                    Pay with multiple Credit Cards
                  </p>
                </div>
              </div>
              {/* onlinr support */}
              <div className="flex flex-row text-center font-semibold text-sm m-2">
                <div className="flex flex-col -red-400 p-4 bg-gray-100 rounded-md">
                  <div className="relative">
                    <img
                      alt="Your Company"
                      src="/images/Vector.png"
                      className="mx-auto relative "
                      width={80}
                      // height={30}
                    />
                    <img
                      alt="Your Companyx"
                       src="/images/Online.png"
                      className="mx-auto absolute bottom-0 ml-1 right-3 mb-2"
                      width={50}
                      // height={30}
                    />
                  </div>
                </div>
                <div className="flex flex-col text-left items-start ml-1">
                  <p className="mt-2">Online Support</p>
                  <p className="mt-2 text-gray-400">24/7 Support</p>
                </div>
              </div>
            </div>
  
            <h2 className="text-left mt-12 font-bold text-xl">Hot Deals</h2>
  
            {/* hot deal section */}
            <HomePageHotDeals />
            {/* popular products */}
            <h2 className="text-left mt-12 font-bold text-xl mb-10">
              Popular Products
            </h2>
  
            <div className="2xl:flex flex-row align-middle items-center justify-between max-w-full ">
              <HomepagePopular />
            </div>
            {/* save amount 20% banner */}
            <div className="2xl:flex  bg-red-400 mt-32 rounded-xl sm:hidden">
              <div className="bg-red-400 w-4/5 rounded-l-xl">
                <div className=" relative ">
                  <div className=" h-80 w-full relative overflow-hidden  ">
                    <div className="ml-24 box-content absolute bottom-12 h-80 w-80 m-auto border bg-white rounded-b-full rounded-l-full rounded-r-full"></div>
                  </div>
                  <div>
                    <img
                      alt="Your Company"
                      src="images/handpic.png"
                      className=" absolute h-96  bottom-0 right-16 "
                      // width={900}
                      // height={30}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-10 ">
                <h2 className="font-bold text-3xl text-white">
                  Score Big Saving on Sport Gear
                </h2>
                <p className="mt-5 text-white">
                Explore a wide range of products across various categories, from drones and gimbals to smartwatches and more. We are excited to announce that you can enjoy discounts of up to 20% on selected items!{" "}
                </p>
                <div className="flex align-middle items-center justify-between mt-10">
                  <h1 className="text-8xl font-semibold text-left text-white">
                    20%
                  </h1>
                  <button className="border px-10 py-2.5 rounded-md mr-28 font-bold text-white tracking-wide outline outline-2" onClick={handleShopNow}>
                    SHOP NOW
                  </button>
                </div>
              </div>
            </div>
  
            {/* brands */}
  
            <div className="2xl:flex flex-col mt-20 sm:hidden ">
              <div className="w-full bg-zinc-100 min-h-[5px] max-md:max-w-full" />
              <div className="flex gap-5 justify-between items-center mt-7 w-full max-md:flex-wrap max-md:max-w-full">
                <img
                  alt="Your Company"
                  src="/images/Rectangle 407.png"
                  width={80}
                  className="shrink-0 self-stretch max-w-full aspect-[1.82] w-[115px]"
                />
                <img
                  alt="Your Company"
                  src="/images/Rectangle 408.png"
                  width={80}
                  className="shrink-0 self-stretch my-auto max-w-full aspect-[3.7] w-[182px]"
                />
                <img
                  alt="Your Company"
                  src="/images/Rectangle 410.png"
                  width={80}
                  className="shrink-0 self-stretch my-auto aspect-[1.64] w-[94px]"
                />
                <img
                  alt="Your Company"
                  src="/images/Potensic.png"
                  width={80}
                  className="shrink-0 self-stretch max-w-full aspect-[3.03] w-[193px]"
                />
                <img
                  alt="Your Company"
                  src="/images/Zeblaze.png"
                  width={80}
                  className="shrink-0 self-stretch my-auto max-w-full aspect-[2.94] w-[161px]"
                />
                <img
                  alt="Your Company"
                  src="/images/Aochuan.png"
                  width={80}
                  className="shrink-0 self-stretch my-auto max-w-full aspect-[4.17] w-[213px]"
                />
              </div>
              <div className="mt-7 w-full bg-zinc-100 min-h-[5px] max-md:max-w-full" />
            </div>
  
            {/* comments */}
            <HomepageComments />
          </div>
  
          {/* the big image */}
        </div>
        <div className="h-56 sm:h-64 xl:h-80 2xl:h-full max-w-full">
          <img
            alt="Your Company"
            src="images/EndImage.png"
            className="w-full"
            //   width={550}
            // height={}
          />
        </div>
  
        <Footer />
      </div>
    );
}

export default Homepage